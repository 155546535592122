html {
    background: #f5f5f5;
    scroll-behavior: smooth;
}

body {
    min-height: 100vh;
}

.iconfont {
    font-size: 16px;
    font-family: iconfont !important;
    font-style: normal;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}
@font-face {
    /* Project id 2542677 */
    font-family: iconfont;
    src: url("//at.alicdn.com/t/font_2542677_opf2plxauw.woff2?t=1620888819062") format("woff2"), url("//at.alicdn.com/t/font_2542677_opf2plxauw.woff?t=1620888819062") format("woff"), url("//at.alicdn.com/t/font_2542677_opf2plxauw.ttf?t=1620888819062") format("truetype");
}

