.hipac-mall-goods-list {
    width: 1200px;
    margin: auto;
    color: #666;
    font-size: 14px;
    .category-area {
        .search-crumbs {
            margin-top: 16px;
            padding-left: 8px;
            display: flex;
            .search-crumbs-item {
                height: 20px;
                line-height: 20px;
                margin-right: 16px;
                color: #FF5C5C;
                >span {
                    padding: 0 8px;
                    display: inline-block;
                    border: 1px dashed #FF5C5C;
                }
                >i {
                    color: #8c8d8d;
                    margin-left: 4px;
                    vertical-align: middle;
                }
                .iconfont {
                    display: inline-block;
                    transform: rotate(90deg);
                }
            }
        }
        .sorry-tips {
            height: 120px;
            display: flex;
            margin-top: 16px;
            align-items: center;
            background: #FFF4E2;
            border: 1px solid #FFAC15;
            img {
                height: 100%;
                margin: 0 40px;
            }
            span {
                color: #666;
                font-size: 16px;
                line-height: 32px;
                font-family: PingFangSC-Regular;
            }
        }
        .search-wrap, .sort-wrap {
            padding: 0 24px;
            background: #fff;
            margin-top: 16px;
            border: 1px solid #d6d6d6;
        }
        .search-wrap {
            >div {
                padding: 8px 0;
                &:not(:last-child) {
                    border-bottom: 1px solid #e1e1e1;
                }
            }
            .search-wrap-category, .search-wrap-classify, .search-wrap-brand {
                display: flex;
                height: 32px;
                overflow-y: hidden;
                >div {
                    width: 86%;
                    display: flex;
                    flex-wrap: wrap;
                    overflow: hidden;
                    .category-item {
                        height: 32px;
                        line-height: 32px;
                        cursor: pointer;
                        margin-right: 16px;
                    }
                    i {
                        font-size: 12px;
                        margin-left: 4px;
                    }
                }
                > span {
                    margin-right: 16px;
                    line-height: 32px;
                }
                .more > i {
                    display: inline-block;
                    transform: rotate(180deg);
                }
                .more, .pack-up {
                    cursor: pointer;
                    align-self: flex-end;
                }
                &.need-more {
                    height: auto;
                    justify-content: initial;
                }
                .active {
                    color: #fff;
                    padding: 0 8px;
                    background: #FF5C5C;
                }
            }
            .search-wrap-brand {
                .category-item {
                    font-size: 12px;
                }
            }
        }
        .sort-wrap {
            padding: 10px 24px;
            display: flex;
            span {
                line-height: 32px;
                padding-right: 12px;
            }
            .order-area {
                display: flex;
                margin-right: 6rem;
                .order-item {
                    width: 64px;
                    padding: 8px 0;
                    text-align: center;
                    cursor:pointer;
                    margin-right: 16px;
                    background: #f2f2f2;
                    border: 1px solid #e1e1e1;
                    i {
                        font-size: 8px;
                        margin-left: 4px;
                    }
                    img {
                        width: 12px;
                        margin-left: 4px;
                        vertical-align: bottom;
                    }
                    &.active {
                        color: #fff;
                        background: #FF5C5C;
                        border-color: #FF5C5C;
                    }
                }
            }
            .sort-area {
                display: flex;
                span{
                    position: relative;
                    img {
                        height: 10px;
                        position: absolute;
                        top: -2px;
                        left: 24px;
                    }
                }
                .sort-item {
                    line-height: 32px;
                    margin-right: 16px;
                    input {
                        margin-right: 8px;
                        vertical-align: baseline;
                    }
                }
            }
        }
        .sort-tips {
            line-height:30px;
            font-size:14px;
            margin-top:16px;
            padding-left:5px
        }
        .banner-img {
            overflow: hidden;
            img {
                width: 100%;
                height: 350px;
            }
        }
    }
    .no-goods {
        height: 200px;
        text-align: center;
        background: #fff;
        margin-top: 16px;
        padding-top: 120px;
        &:hover {
            border: 1px solid #FF475E;
        }
        >div {
            width: 400px;
            margin: auto;
            text-align: left;
        }
        .no-goods-title {
            margin-bottom: 24px;
            span {
                color: #FF5C5C;
            }
        }
        .no-goods-info {
            margin: auto;
            font-size: 12px;
            line-height: 16px;
        }
        .not-key {
            width: 200px;
        }
    }
}
.hipac-mall {
    padding-bottom: 40px;
    background: #eee;

}

