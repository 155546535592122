.goods-module {
    padding-top: 10px;
    &:not(:last-child) {
        margin-bottom: 24px;
    }
    .goods-module-head {
        display: flex;
        margin-bottom: 8px;
        justify-content: space-between;
        .more {
            line-height: 32px;
        }
        .title {
            display: flex;
            justify-content: space-between;
            div:first-child {
                color: #333;
                font-size: 21px;
                line-height: 32px;
                margin-right: 24px;
            }
            div:last-child {
                color: #999;
                line-height: 32px;
                a {
                    padding: 0 8px;
                    cursor: pointer;
                    color: #666;
                    font-size: 14px;
                    &:not(:first-child) {
                        border-left: 1px solid #d6d6d6;
                    }
                }
            }
        }
    }
    .goods-module-body {
        background: #fff;
        display: flex;
        height: 470px;
        position: relative;
        overflow: hidden;
        border: 1px solid #d6d6d6;
        .big-img {
            width: 204px;
            flex-shrink: 0;
            border-right: 1px solid #d6d6d6;
            > a {
                >div {
                    height: 100%;
                }
            }
            img {
                width: 204px;
            }
        }
        .img-list {
            display: flex;
            flex-shrink: 0;
            flex-wrap: wrap;
            width: 1015px;
            overflow: hidden;
            cursor: pointer;
            .goods-item {
                position: relative;
                width: 201px;
                height: 233px;
                padding-top: 2px;
                flex-shrink: 0;
                border-right: 1px solid #d6d6d6;
                border-bottom: 1px solid #d6d6d6;
                a:hover {
                    text-decoration: none;
                }
                .tag-img {
                    width: 145px;
                    height: 145px;
                    line-height: 145px;
                    margin: auto;
                    overflow: hidden;
                    img:last-child {
                        width: 100%;
                        vertical-align: middle;
                    }
                    .mask-img {
                        position: absolute;
                        top: 30px;
                        left: 52px;
                    }
                }
                .tag-content{
                    padding: 4px 16px;
                    .tag-name {
                        height:40px;
                        line-height: 20px;
                        overflow: hidden;
                        text-align: left;
                        word-break: break-all;
                        font-size: 14px;
                        .act-tag{
                            background-image: linear-gradient(-90deg, #E83562 0%, #FF5C5C 100%);
                            line-height:14px;
                            font-size: 14px;
                            color: #fff;
                            padding:0 3px;
                            margin-right:5px ;
                        }
                    }
                    .tag-price {
                        overflow: hidden;
                        text-align: left;
                        font-size: 16px;
                        line-height: 16px;
                        color:#FF3F50;
                        margin-top: 4px;
                        word-break: break-all;
                    }
                    .tag-price-promotion {
                        margin: 8px 0 0 26px;

                        .yuan-price{
                            color: #9B9B9B;
                            font-size: 14px;
                            height: 14px;
                            line-height: 14px;
                            overflow: hidden;
                            word-break: break-all;
                            text-decoration: line-through;
                        }
                        .act-price{
                            color: #9B9B9B;
                            font-size: 13px;
                            height: 14px;
                            line-height: 14px;
                            overflow: hidden;
                            word-break: break-all;
                            .cu-icon{
                                background: #7ED321;
                                color: #fff;
                                padding: 1px 2px;
                                border-radius: 3px;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
        .goods-module-brand {
            margin-top: 16px;
            border: 1px solid #d6d6d6;
        }
    }

    .goods-module-brand {
        background: #fff;
        display: flex;
        justify-content: space-between;
        border: 1px solid #d6d6d6;
        background: #fff;
        margin-top: 16px;
        padding: 16px 0;
        div:first-child {
            color: #333;
            font-size: 14px;
            width: 200px;
            text-align: center;
            height: 100px;
            line-height: 100px;
        }
        .brand-list {
            display: flex;
            height: 100px;
            width: 1000px;
            flex-shrink: 0;
            overflow: hidden;
            .brand-list-item {
                width: 100px;
                overflow: hidden;
                flex-shrink: 0;
                line-height: 100px;
                &:not(:last-child) {
                    margin-right: 24px;
                }
            }
        }
    }
}

