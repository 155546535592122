.pc-modal {
    position: relative;

    .pc-modal-mask {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
        background: rgba(0, 0, 0, 0.5);
        animation: dialog-maxk 0.1s cubic-bezier(0.89, 0.23, 0.56, 0.83);
    }

    .pc-modal-body {
        position: fixed;
        top: 20%;
        left: 28%;
        z-index: 9999;
        width: 650px;
        height: 400px;

        .close {
            position: absolute;
            top: 16px;
            right: 24px;
            width: 32px;
            height: 32px;
            color: #fff;
            font-size: 28px;
            line-height: 28px;
            text-align: center;
            background: #FF5C5C;
            border-radius: 50%;
            cursor: pointer;
        }
    }
}

@keyframes dialog-maxk {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

