.block-slide {
    margin: 24px 0;
    .block-slide-title {
        display: flex;
        padding: 12px 8px 16px 4px;
        justify-content: space-between;
        .title {
            color: #333;
            font-size: 21px;
            margin-right: 24px;
        }
        .sub-title {
            color: #999;
        }
        .more {
            font-size: 12px;
            color: #666;
            text-decoration: none;
        }
    }

    .block-slide-content {
        background: #fff;
        height: 320px;
        position: relative;
        overflow: hidden;
        border: 1px solid #d6d6d6;
        .btn {
            display: block;
            position: absolute;
            width: 40px;
            height: 75px;
            margin-top: -37px;
            top: 50%;
            background-image: url(https://img.hicdn.cn/fed/images/20210407/d24b8523a048ad83dffcf887c77934a8_51x25.png);
            background-position: 10px 25px;
            background-repeat: no-repeat;
            background-color: #333;
            z-index: 20;
            cursor: pointer;
            opacity: 0.1;
            filter: alpha(opacity=10);
            &:hover {
                opacity: 0.3;
                filter: alpha(opacity=30);
            }
        }
        .prev-btn{
            left:0;
        }
        .next-btn{
            right: 0;
            background-position: -20px 25px;
        }
        .animate {
            transition: left .9s ease-in-out;
        }
        .slide-list {
            display: flex;
            position: absolute;
            .slide-list-item {
                position: relative;
                width: 242px;
                height: 312px;
                padding-top: 8px;
                flex-shrink: 0;
                &:not(:last-child) {
                    border-right: 1px solid #d6d6d6;
                }
                .item-url {
                    cursor: pointer;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 9;
                }
                .tag-img {
                    width: 200px;
                    height: 200px;
                    margin: auto;
                    overflow: hidden;
                    img:last-child {
                        width: 100%;
                    }
                    .mask-img {
                        position: absolute;
                        top: 56px;
                        left: 72px;
                    }
                }
                .tag-name {
                    width: 200px;
                    height:48px;
                    line-height: 24px;
                    overflow: hidden;
                    text-align: left;
                    word-break: break-all;
                    margin: 8px auto auto;
                    font-size: 14px;
                    .act-tag{
                        background-image: linear-gradient(-90deg, #E83562 0%, #FF5C5C 100%);
                        line-height:14px;
                        font-size: 14px;
                        color: #fff;
                        padding:0 3px;
                        margin-right:5px ;
                    }
                }
                .tag-price {
                    width: 200px;
                    margin: auto;
                    overflow: hidden;
                    text-align: left;
                    font-size: 18px;
                    color:#FF3F50;
                    word-break: break-all;
                }
                .tag-price-promotion {
                    margin: 8px 0 0 26px;

                    .yuan-price{
                        color: #9B9B9B;
                        font-size: 14px;
                        height: 14px;
                        line-height: 14px;
                        overflow: hidden;
                        word-break: break-all;
                        text-decoration: line-through;
                    }
                    .act-price{
                        color: #9B9B9B;
                        font-size: 13px;
                        height: 14px;
                        line-height: 14px;
                        overflow: hidden;
                        word-break: break-all;
                        .cu-icon{
                            background: #7ED321;
                            color: #fff;
                            padding: 1px 2px;
                            border-radius: 3px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

