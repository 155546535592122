.flash-buy {
    height: 200px;
    padding: 20px 40px 0 40px;
    display: flex;
    justify-content: space-between;
    position: relative;
    background: #fff;
    > div {
        height: 200px;
        a:hover {
            text-decoration: none;
        }
    }
    .flash-buy-sale {
        display: flex;
        width: 380px;
        flex-direction: column;
        justify-content: space-between;
        .big-deal {
            color: #fff;
            padding: 0 5px;
            background: #feb500;
            margin-right: 8px;
        }
        .name {
            color: #444;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
        }
        .desc {
            color: #666;
            line-height: 24px;
            margin-top: 16px;
            font-size: 12px;
            letter-spacing: 1px;
        }
        .btn {
            margin-bottom: 24px;
            a {
                text-align: center;
                display: inline-block;
                border-radius: 2px;
                width: 120px;
                padding: 0 16px;
                color: #fff;
                height: 32px;
                line-height: 32px;
                font-size: 18px;
                background: #ed3a4a;
            }
            span {
                font-size: 12px;
                color: #FF5C5C;
                margin-left: 10px;
                vertical-align: bottom;
            }
        }
    }
    .flash-buy-goods {
        display: flex;
        margin: 0 24px;
        .flash-buy-goods-item {
            width: 130px;
            position: relative;
            margin-right: 20px;
            .flash-img {
                height: 130px;
                img:last-child {
                    width: 100%;
                    height: 100%;
                }
                .mask-img {
                    position: absolute;
                    top: 16px;
                    left: 24px;
                }
            }
            .flash-name {
                line-height: 16px;
                font-size: 16px;
                margin: 12px 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                .high-light {
                    color: #FF5C5C;
                }
            }
            .flash-info {
                color: #FF5C5C;
                font-size: 18px;
            }
        }
    }
    .flash-buy-image {
        .go-more {
            color: #FF5C5C;
            font-size: 16px;
            text-align: center;
            padding-top: 8px;
        }
    }
}

