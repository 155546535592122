.hipac-mall-content {
    padding-bottom: 40px;
    background: #eee;

    .hipac-mall-container {
        width: 1216px;
        margin: auto;

        .little-img {
            display: flex;
            flex-wrap: wrap;

            .little-img-item {
                width: 286px;
                margin-top: 16px;
                margin-left: 16px;
                vertical-align: top;

                img {
                    display: block;
                    width: 100%;
                    border: 0;
                }
            }
        }
    }

    .app-down {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 999;
        width: 100%;

        >div {
            position: relative;

            .bg {
                height: 120px;
                background: #000;
                opacity: 0.7;
            }

            .app-down-img {
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 1022px;
                margin-left: -511px;

                img {
                    width: 1022px;
                }

                .close {
                    position: absolute;
                    right: 32px;
                    width: 20px;
                    height: 20px;
                    color: #fff;
                    font-size: 16px;
                    line-height: 16px;
                    text-align: center;
                    background: #b61a1a;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }
        }
    }
}


