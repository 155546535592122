.hipac-mall {
    padding-bottom: 40px;
    background: #eee;
    .hipac-global-search-menu-wraps {
        .hipac-global-search-menu-all {
            cursor: pointer;
            .hipac-global-front-categories {
                display: none;
            }
            &:hover {
                .hipac-global-front-categories {
                    display: block;
                }
            }
        }
    }
    a {
        color: #666;
        font-size: 12px;
        text-decoration: none;
    }
}
.show-category {
    .hipac-global-search-menu-wraps {
        .hipac-global-search-menu-all {
            .hipac-global-front-categories {
                display: block;
            }
        }
    }
}

