.goods-list {
    margin-top: 16px;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    padding: 0 2px;
    .goods-item {
        width: 224px;
        height: 360px;
        overflow: hidden;
        position: relative;
        background: #fff;
        border: 1px solid #d6d6d6;
        margin-bottom: 20px;
        &:not(:nth-child(5n)) {
            margin-right: 16px;
        }
        .goods-item-image {
            height: 224px;
            border-bottom: 1px solid #d6d6d6;
            .tag-img {
                top: 12px;
                left: 12px;
                width: 40px;
                height: 40px;
                position: absolute;
            }
            .mask-img {
                position: absolute;
                top: 64px;
                left: 72px;
            }
            .main-img{
                height: 224px;
                width: 100%;
            }
        }
        .goods-item-box {
            padding: 16px 8px;
            .base-info {
                height: 12px;
                font-size: 12px;
                .split {
                    color: #666;
                }
                .payment {
                    color: #F48A93;
                }
                span {
                    display: inline-block;
                    line-height: 12px;
                    padding-right: 6px;
                }
                >span:last-child {
                    float: right;
                }
                .color-green{
                    color:#7ED321;
                }
                .color-violet{
                    color:#C889FF;
                }
                .color-red{
                    color:#F28A93;
                }
            }
            .title {
                color: #444;
                height: 42px;
                margin-top: 12px;
                overflow: hidden;
                a {
                    font-size: 14px;
                    line-height: 20px;
                    &:hover {
                        text-decoration: none;
                    }
                }
                .act-tag {
                    color: #fff;
                    padding: 1px 3px;
                    margin-right: 8px;
                    line-height: 22px;
                    background: linear-gradient(-90deg,#E83562 0,#FF5C5C 100%);
                }
            }
            .origin-price {
                color: #FF3F50;
                font-size: 18px;
                line-height: 18px;
                margin: 6px 0 3px 0;
                word-break: break-all;
            }
            .cue-price {
                .yuan-price {
                    color: #9B9B9B;
                    height: 14px;
                    line-height: 14px;
                    overflow: hidden;
                    text-decoration: line-through;
                }
                .act-price{
                    color: #9B9B9B;
                    font-size: 14px;
                    height: 14px;
                    line-height: 14px;
                    overflow: hidden;
                    .cu-icon{
                        background: #7ED321;
                        color: #fff;
                        padding: 1px 1px;
                        border-radius: 3px;
                        font-size: 12px;
                        margin-right: 5px;
                    }
                }
            }
        }
        &:hover {
            border: 1px solid #FF475E;
            .goods-item-image {
                border-bottom: 1px solid #FF475E;
            }
        }
    }
}

