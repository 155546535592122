.notice-modal {
    position: relative;
    animation: fade-in 0.1s cubic-bezier(0.89, 0.23, 0.56, 0.83);

    .notice-modal-mask {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10000;
        background: rgba(0, 0, 0, 0.5);
    }

    .notice-modal-body {
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 10000;
        display: flex;
        flex-direction: column;
        width: 640px;
        height: 640px;
        background: #fff;
        border-radius: 16px;
        transform: translate(-50%, -50%);

        .notice-modal-title {
            padding: 24px;
            color: #333;
            font-weight: bold;
            font-size: 28px;
            text-align: center;
        }

        .notice-modal-content {
            flex: 1;
            padding: 0 32px 24px;
            overflow: auto;
            color: #666;
            font-size: 16px;
            line-height: normal;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            strong {
                font-weight: bold;
            }

            h1 {
                font-size: 2em;
            }

            h2 {
                font-size: 1.5em;
            }

            h3 {
                font-size: 1.17em;
            }

            h4 {
                font-size: 1em;
            }

            h5 {
                font-size: 0.83em;
            }

            h6 {
                font-size: 0.67em;
            }

            p {
                min-height: 18px;
            }

            em {
                font-style: italic;
            }
        }

        .notice-modal-footer {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 24px;
            background-color: #F7F7F7;
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;

            .notice-modal-btn {
                width: 240px;
                height: 56px;
                margin: 0 16px;
                font-weight: bold;
                font-size: 20px;
                line-height: 56px;
                text-align: center;
                border-radius: 28px;
                cursor: pointer;

                &.cancel-btn {
                    color: #333;
                    background-color: rgba(199, 199, 199, 0.5);
                }

                &.ok-btn {
                    color: #fff;
                    background: #FD2E40;

                    &.disabled {
                        background: rgba(253, 46, 64, 0.5);
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}
  
@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
  
