.carousel-container {
    height: 450px;
    margin: 0 auto;
    overflow: hidden;
    cursor: pointer;
    position: relative;
  .img-ul {
    height: 100%;
    margin-top: 0;
    list-style: none;
    padding: 0;
    li {
      overflow: hidden;
      height: 450px;
      width: 100%;
      opacity: 0;
      position: absolute;
      z-index:1;
      transition: opacity 1s;
        text-align: center;
      &.show{
        z-index: 2;
        opacity: 1;
      }
      a {
          img {
              display: inline-block;
              position: absolute;
              left: 50%;
              margin-left: -960px;
          }
      }
    }
  }
  .dots {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    z-index: 3;
    li {
      float: left;
      width: 12px;
      height: 12px;
      margin: 0 5px;
      border-radius: 50%;
      transition: all .5s;
      background-color: #675D71;
      list-style: none;
      &.active {
        background-color: #FF5C5C;
      }
    }
  }
  .control {
     visibility: hidden;
     width: 1200px;
     position: relative;
     margin: auto;
    .item {
      width: 24px;
      height: 58px;
      position: absolute;
      padding: 5px;
      transform: translateY(-50%);
      font-size: 20px;
      cursor: pointer;
      z-index: 99;
      opacity: .4;
      margin-top: -240px;
      &:hover {
        background-color: rgba(#000000,  0.3);
      }
    }
    .left {
      left: 200px;
      background: #000 url(//img.hicdn.cn/mallpc/index/jt.png) no-repeat 10px 22px;
    }
    .right {
      right: 0;
      background: #000 url(//img.hicdn.cn/mallpc/index/jt.png) no-repeat -25px 22px;
    }
  }
  &:hover .control {
    visibility: visible;
  }
}

