.pc-mall-lift {
    width: 36px;
    position: fixed;
    z-index: 999;
    top: 240px;
    left: 50px;
    padding: 4px 0;
    background: #fff;
    .lift-item {
        padding:  6px;
        width: 22px;
        display: flex;
        font-weight: bold;
        cursor: pointer;
        overflow: hidden;
        transition: width .3s;
        justify-content: space-between;
        &:hover {
            .info {
                display: block;
            }
            width: 90px;
            color: #fff;
            padding-right: 16px;
            background: rgb(255, 92, 92);
            img{
                left: -30px;
                filter: drop-shadow(#fff 30px 0);
            }
        }
        &:not(:last-child) {
            margin-bottom: 4px;
        }
        .icon {
            margin-right: 4px;
            img {
                width: 21px;
                height: 21px;
                position: relative;
            }
        }
        .info {
            display: none;
            flex-shrink: 0;
            line-height: 22px;
        }
    }
}

